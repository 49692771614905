import React, { useState } from "react"
import { Link } from "gatsby"
import ResourcePartner from "../data/resource-partners"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"
import "./resource-partners.css"

const ResourcePartners = () => {
  const [resource, setResourcePartners] = useState(ResourcePartner.partners)
  return (
    <Layout>
      <SEO title="Resource and Partners" />
      <div className="container primary-bg">
        <h1 className="small-padding"> Resource and Partners </h1>
        <p className="small-padding">
          Supporting Miami-Dade's business community in time of crisis cannot be
          done alone. It is critical that we all take advantage of the strength
          of available business assets and resources.
        </p>
      </div>
      <section className="container">
        <label className="labelText">AXIShelp</label>
        {resource
          .filter(resource => resource.label == "axishelps")
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((resource, index) => {
            return (
              <div className="card">
                <label className="additonalFundingCardTitle">
                  {resource.name}
                </label>
                <div className="rowSpace">
                  <FontAwesomeIcon
                    className="iconStyle"
                    size="lg"
                    icon={faGlobe}
                  />
                  <a className="techLinks" href={resource.link} target="_blank">
                    {resource.link}
                  </a>
                </div>
              </div>
            )
          })}
      </section>
    </Layout>
  )
}

export default ResourcePartners
